import { FormInstance } from "twilio/lib/rest/verify/v2/form";

export const auth = (to, from, next) => {
  const token = localStorage.getItem("accessToken");
  const isLoggedIn = localStorage.getItem("loggedIn");

  const publicPages = [
    "/login",
    "/forgot-password",
    "/verify-email/" + to.params.token,
    "/reset-password/" + to.params.token,
    "/pages/error-419",
    "/admin/login",
    "/success",
    // "/google-login"
  ];
  console.log('from : ',from)
  console.log("initial to : ", to, FormInstance);
  const authRequired = !publicPages.includes(to.path);

  // if(!authRequired && publicPages.includes(to.path)) {
  //   console.log("IN")
  //   return next();
  // }

  if (!authRequired && isLoggedIn && token) {
    console.log("INSIDE AND INSIDE")
    const user = JSON.parse(localStorage.getItem("userInfo"));
    // if (user.userType === "admin") {
      return next("/admin/login");
    // }
  }

  if (authRequired && !isLoggedIn && !token && to.name !== 'user-reset-password') {
    console.log("AuthRequired", authRequired);
    if (from.path !== "/admin/login") {
      return next("/admin/login");
    }
  }

  if(to.path === "/login" || to.path === '/admin/login'){

    if(isLoggedIn){
      return next("/admin/dashboard");
    }
  }
  console.log('final to : ',to);

  return next();
  
  // if (!authRequired && isLoggedIn && token) {
  //   const user = JSON.parse(localStorage.getItem("userInfo"));
  //   if (user.userType === "admin") {
  //     return next("/admin/dashboard");
  //   }
  // }

  // if (authRequired && !isLoggedIn && !token && to.name !== 'user-reset-password' && to.name !=='google-login') {
  //   console.log("AuthRequired", authRequired);
  //   if (from.path !== "/admin/login") {
  //     return next("/admin/login");
  //   }
  // }

  // if(to.path === "/login" || to.path === '/admin/login'){

  //   if(isLoggedIn){
  //     return next("/admin/dashboard");
  //   }
  // }
  // console.log('final to : ',to);

  // return next();

};
