/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
// import auth from "@/auth/authService";
import store from "./store/store";
import { auth } from "@/auth/auth";
import "firebase/auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/admin",
      redirect: "/admin/dashboard",
      name: "Admin",
      meta: {
        rule: "admin",
      },
      component: () => import("./layouts/main/Main.vue"),
      children: [
        {
          path: "user",
          name: "User",
          component: () => import("./views/user/UserList.vue"),
          meta: {
            pageTitle: "Users",
            rule: "admin",
            requiresAuth: false,
          },
        },
        {
          path: "dashboard",
          name: "Home",
          component: () => import("./views/Dashboard.vue"),
          meta: {
            pageTitle: "Dashboard",
            rule: "admin",
            requiresAuth: true,
          },
        },

        {
          path: "cms-pages",
          name: "CMSPages",
          component: () => import("./views/cms-pages/CMSPage.vue"),
          meta: {
            pageTitle: "Pages",
            rule: "admin",
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },

              { title: "Pages List", active: true },
            ],
            requiresAuth: true,
          },
        },
        {
          path: "cms-pages/:id",
          name: "CMSPageDetails",
          component: () => import("./views/cms-pages/CMSPageDetails.vue"),
          meta: {
            pageTitle: "Page Details",
            rule: "admin",
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              // { title: "Page Management", url: "#" },
              { title: "Pages List", url: "/admin/cms-pages" },
              { title: "Page Detail", active: true },
            ],
            requiresAuth: true,
          },
        },
        {
          path: "user-management",
          name: "AppUsers",
          component: () => import("./views/user-management/app-users.vue"),
          meta: {
            pageTitle: "Users",
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Users", active: true },
            ],
            rule: "admin",
            //'authRequired': true
          },
        },
        {
          path: "user-management/newuser",
          name: "AddUser",
          component: () => import("./views/user-management/AddUser.vue"),
          meta: {
            pageTitle: "Add New User",
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Users", url: "/admin/user-management" },
              { title: "New", active: true },
            ],
            rule: "admin",
            //'authRequired': true
          },
        },
        {
          path: "user-management/view/:id",
          name: "UserDetail",
          component: () => import("./views/user-management/userDetails.vue"),
          meta: {
            pageTitle: "User Profile",
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Users", url: "/admin/user-management" },
              { title: "View", active: true },
            ],
            rule: "admin",
            //'authRequired': true
          },
        },
        {
          path: "user-management/edit/:id",
          name: "EditUserDetail",
          component: () => import("./views/user-management/EditUser.vue"),
          meta: {
            pageTitle: "Edit User Profile",
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Users", url: "/admin/user-management" },
              { title: "Edit", active: true },
            ],
            rule: "admin",
            //'authRequired': true
          },
        },
        {
          path: "admin-user-management",
          name: "Admin Users",
          component: () => import("./views/user-management/admin/users.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Admin Users", active: true },
            ],
            pageTitle: "Admin Users",
            rule: "admin",
            //'authRequired': true
          },
        },
        {
          path: "admin-user-management/:id",
          name: "AdminDetail",
          component: () =>
            import("./views/user-management/admin/userDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Admin Users", active: true },
            ],
            pageTitle: "Admin Profile",
            rule: "admin",
            authRequired: true,
          },
        },

        {
          path: "venue-reports",
          name: "ReportedVenues",
          component: () => import("./views/report/VenueReportList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Reported Venues", active: true },
            ],
            pageTitle: "Reported Venues",
            rule: "admin",
            //'authRequired': true
          },
        },
        {
          path: "user-reports",
          name: "ReportedUsers",
          component: () => import("./views/report/UserReportList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Reported Users", active: true },
            ],
            pageTitle: "Reported Users",
            rule: "admin",
            //'authRequired': true
          },
        },
        {
          path: "venue-management/view/:id",
          name: "VenueDetail",
          component: () => import("./views/venue-management/venueDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Venue", active: true },
            ],
            pageTitle: "Venue Information",
            rule: "admin",
            authRequired: true,
          },
        },

        {
          path: "subscriptions",
          name: "Subscription",
          component: () => import("./views/subscription-management/index.vue"),
          meta: {
            pageTitle: "Subscription Management",
            rule: "admin",
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Subscription Management", active: true },
            ],
          },
        },
        {
          path: "subscription-management/details/:id",
          name: "subscriptionDetail",
          component: () =>
            import("./views/subscription-management/details.vue"),
          meta: {
            pageTitle: "Subscription Details",
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Subscription Management", url: "/admin/subscriptions" },
              { title: "Subscriptions Details", active: true },
            ],
            rule: "admin",
            // authRequired: true
          },
        },
        {
          path: "postcodes",
          name: "postalCode",
          component: () => import("./views/postcode-management/index.vue"),
          meta: {
            pageTitle: "Postal codes Management",
            rule: "admin",
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: " Postal codes Management", active: true },
            ],
          },
        },

        {
          path: "recommendations",
          name: "Location Recommendations",
          component: () =>
            import("./views/recommendations/Recommendations.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Location Recommendations", active: true },
            ],
            pageTitle: "Location Recommendations",
            rule: "admin",
            authRequired: true,
          },
        },
        {
          path: "recommendations/:id",
          name: "LocationRecommendationDetails",
          component: () =>
            import("./views/recommendations/RecommendationDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              {
                title: "Location Recommendations",
                url: "/admin/recommendations",
              },
              { title: "Details", active: true },
            ],
            pageTitle: "Location Recommendation Details",
            rule: "admin",
            authRequired: true,
          },
        },
        {
          path: "subscriptions",
          name: "Subscription",
          component: () => import("./views/subscription-management/index.vue"),
          meta: {
            pageTitle: "Subscription Management",
            rule: "admin",
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Subscription Management", active: true },
            ],
          },
        },
        {
          path: "subscription-management/details/:id",
          name: "subscriptionDetail",
          component: () =>
            import("./views/subscription-management/details.vue"),
          meta: {
            pageTitle: "Subscription Details",
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Subscription Management", url: "/admin/subscriptions" },
              { title: "Subscriptions Details", active: true },
            ],
            rule: "admin",
            // authRequired: true
          },
        },
        {
          path: "admin-user-management/newuser",
          name: "AddAdminDetail",
          component: () => import("./views/user-management/admin/userForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Admin Users", url: "/admin/admin-user-management" },
              { title: "Add New Admin ", active: true },
            ],
            pageTitle: "Add New Admin",
            rule: "admin",
          },
        },
        {
          path: "admin-user-management/edit/:id",
          name: "EditAdminDetail",
          component: () => import("./views/user-management/admin/userForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Admin Users", url: "/admin/admin-user-management" },
              { title: "Edit Admin User ", active: true },
            ],
            pageTitle: "Edit Admin User",
            rule: "admin",
          },
        },
        {
          path: "admin-user-management/change-password/:id",
          name: "ChangeAdminPassword",
          component: () =>
            import("./views/user-management/admin/changePassword.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Admin Users", url: "/admin/admin-user-management" },
              { title: "Change Password ", active: true },
            ],
            pageTitle: "Change Admin Password",
            rule: "admin",
          },
        },
        {
          path: "/admin/profile",
          name: "admin-profile",
          component: () => import("@/views/pages/adminProfile.vue"),
          meta: {
            rule: "admin",
          },
        },
        {
          path: "/admin/my-details",
          name: "my-details",
          component: () => import("@/views/cms-pages/MyDetails.vue"),
          meta: {
            rule: "admin",
          },
        },
        {
          path: "/admin/email-template",
          name: "email-template",
          component: () => import("@/views/cms-pages/email/EmailTemplate.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Email Templates", active: true },
            ],
            pageTitle: "Email Templates",
            rule: "admin",
          },
        },
        {
          path: "/admin/email-template/:id",
          name: "edit-email-template",
          component: () =>
            import("./views/cms-pages/email/EditEmailTemplate.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Email Templates", url: "/admin/email-template" },
              { title: "Template", active: true },
            ],
            pageTitle: "Email Template",
            rule: "admin",
          },
        },
        {
          path: "settings",
          name: "Settings",
          component: () => import("./views/settings/Settings.vue"),
          meta: {
            pageTitle: "Settings",
            rule: "admin",
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Settings", active: true },
            ],
            requiresAuth: true,
          },
        },
        {
          path: "/admin/push-notifications",
          name: "push-notifications",
          component: () =>
            import("@/views/push-notifications/PushNotificationsList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Push Notifications", active: true },
            ],
            // pageTitle: 'Push Notifications',
            rule: "admin",
          },
        },
        {
          path: "/admin/push-notifications/send",
          name: "push-notifications-send",
          component: () =>
            import("./views/push-notifications/SendPushNotification.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Push Notifications", url: "/admin/push-notifications" },
              { title: "Send", active: true },
            ],
            // pageTitle: 'Send Push Notification',
            rule: "admin",
          },
        },
      ],
    },
    {
      path: "postcode/create",
      name: "postalCodeCreate",
      component: () => import("./views/postcode-management/create.vue"),
      meta: {
        pageTitle: "Postalcodes",
        breadcrumb: [
          { title: "Home", url: "/admin/dashboard" },
          { title: "Postal Code List ", active: true },
        ],
        rule: "admin",
        authRequired: true,
      },
    },
    {
      path: "postcode/edit/:id",
      name: "postalCodeEdit",
      component: () => import("./views/postcode-management/create.vue"),
      meta: {
        pageTitle: "Postalcodes",
        breadcrumb: [
          { title: "Home", url: "/admin/dashboard" },
          { title: "Postal Code List", url: "/admin/postcodes" },
          { title: "Edit Postal Code", active: true },
        ],
        rule: "admin",
        authRequired: true,
      },
    },
    {
      path: "admin-user-management/edit/:id",
      name: "EditAdminDetail",
      component: () => import("./views/user-management/admin/userForm.vue"),
      meta: {
        breadcrumb: [
          { title: "Home", url: "/admin/dashboard" },
          { title: "Admin Users", url: "/admin/admin-user-management" },
          { title: "Edit Admin User ", active: true },
        ],
        pageTitle: "Edit Admin User",
        rule: "admin",
      },
    },
    {
      path: "admin-user-management/change-password/:id",
      name: "ChangeAdminPassword",
      component: () =>
        import("./views/user-management/admin/changePassword.vue"),
      meta: {
        breadcrumb: [
          { title: "Home", url: "/admin/dashboard" },
          { title: "Admin Users", url: "/admin/admin-user-management" },
          { title: "Change Password ", active: true },
        ],
        pageTitle: "Change Admin Password",
        rule: "admin",
      },
    },
    {
      path: "/admin/profile",
      name: "admin-profile",
      component: () => import("@/views/pages/adminProfile.vue"),
      meta: {
        rule: "admin",
      },
    },
    {
      path: "/admin/my-details",
      name: "my-details",
      component: () => import("@/views/cms-pages/MyDetails.vue"),
      meta: {
        rule: "admin",
      },
    },
    {
      path: "/admin/email-template",
      name: "email-template",
      component: () => import("@/views/cms-pages/email/EmailTemplate.vue"),
      meta: {
        breadcrumb: [
          { title: "Home", url: "/admin/dashboard" },
          { title: "Email Templates", active: true },
        ],
        pageTitle: "Email Templates",
        rule: "admin",
      },
    },
    {
      path: "/admin/email-template/:id",
      name: "edit-email-template",
      component: () => import("./views/cms-pages/email/EditEmailTemplate.vue"),
      meta: {
        breadcrumb: [
          { title: "Home", url: "/admin/dashboard" },
          { title: "Email Templates", url: "/admin/email-template" },
          { title: "Template", active: true },
        ],
        pageTitle: "Email Template",
        rule: "admin",
      },
    },
    {
      path: "settings",
      name: "Settings",
      component: () => import("./views/settings/Settings.vue"),
      meta: {
        pageTitle: "Settings",
        rule: "admin",
        breadcrumb: [
          { title: "Home", url: "/admin/dashboard" },
          { title: "Settings", active: true },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/admin/push-notifications",
      name: "push-notifications",
      component: () =>
        import("@/views/push-notifications/PushNotificationsList.vue"),
      meta: {
        breadcrumb: [
          { title: "Home", url: "/admin/dashboard" },
          { title: "Push Notifications", active: true },
        ],
        // pageTitle: 'Push Notifications',
        rule: "admin",
      },
    },
    {
      path: "/admin/push-notifications/send",
      name: "push-notifications-send",
      component: () =>
        import("./views/push-notifications/SendPushNotification.vue"),
      meta: {
        breadcrumb: [
          { title: "Home", url: "/admin/dashboard" },
          { title: "Push Notifications", url: "/admin/push-notifications" },
          { title: "Send", active: true },
        ],
        // pageTitle: 'Send Push Notification',
        rule: "admin",
      },
    },
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          redirect: "/admin/login",
        },
        {
          path: "/admin/change-password",
          name: "change-password",
          component: () => import("@/views/pages/admin/ChangePassword.vue"),
          meta: {
            rule: "editor",
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/callback",
          name: "auth-callback",
          component: () => import("@/views/Callback.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/agoda/:searchtext",
          name: "Agoda",
          meta: {
            rule: "editor",
            authRequired: false,
          },
          component: () => import("@/views/Agoda.vue"),
        },
        {
          path: "/admin/login",
          name: "page-login",
          component: () => import("@/views/pages/login/Login.vue"),
          meta: {
            rule: "editor",
            requiresAuth: true,
          },
        },
        {
          path: "/google",
          name: "google-login",
          component: () => import("@/views/pages/login/GoogleLogin.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
        },
        {
          path: "/pages/register",
          name: "page-register",
          component: () => import("@/views/pages/register/Register.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/forgot-password",
          name: "page-forgot-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/set-password/:token",
          name: "user-forgot-password",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/checkTokenExpiry", payload)
              .then(() => {
                next();
              })
              .catch((error) => {
                next("/pages/error-419");
              });
          },
          component: () => import("@/views/pages/setPassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/verify-email/:token",
          name: "user-account-verification",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/verifyMail", payload)
              .then(() => {
                return next();
                //next("/admin/login");
              })
              .catch((error) => {
                console.log("E", error);
                return next("/pages/error-419");
              });
          },
          component: () => import("@/views/pages/verificationSuccess.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/reset-password",
          name: "page-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
        },
        {
          path: "/reset-password/:token",
          name: "user-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/isResetTokenExpired", payload)
              .then(() => {
                next();
              })
              .catch((error) => {
                next("/pages/error-419");
              });
          },
        },
        {
          path: "/success",
          name: "reset-password-success",
          component: () => import("@/views/pages/Success.vue"),
          meta: {
            rule: "editor",
          },
        },
        // {
        //   path: "/pages/lock-screen",
        //   name: "page-lock-screen",
        //   component: () => import("@/views/pages/LockScreen.vue"),
        //   meta: {
        //     rule: "editor"
        //   }
        // },

        // {
        //   path: "/pages/comingsoon",
        //   name: "page-coming-soon",
        //   component: () => import("@/views/pages/ComingSoon.vue"),
        //   meta: {
        //     rule: "editor"
        //   }
        // },
        {
          path: "/pages/error-419",
          name: "page-error-419",
          component: () => import("@/views/pages/Error419.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-500",
          name: "page-error-500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/not-authorized",
          name: "page-not-authorized",
          component: () => import("@/views/pages/NotAuthorized.vue"),
          meta: {
            rule: "editor",
          },
        },
        // {
        //   path: "/pages/maintenance",
        //   name: "page-maintenance",
        //   component: () => import("@/views/pages/Maintenance.vue"),
        //   meta: {
        //     rule: "editor"
        //   }
        // },
        {
          path: "/pages/privacy-policy/:slug",
          name: "PrivacyPolicy",
          component: () => import("@/views/pages/PrivacyPolicy.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/terms-and-conditions/:slug",
          name: "TermsAndConditions",
          component: () => import("@/views/pages/Terms.vue"),
          meta: {
            rule: "editor",
          },
        },
      ],
    },
    // Redirect to 404 page, if no match found
  ],
});

router.afterEach((to) => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
  document.title = to.meta.pageTitle || "Kulcha";
});

//this method is calling in each router navigate
//commented cause of reset-password link doesn't required to auth
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    to.name !== "page-login"
  ) {
    auth(to, from, next);
  } else {
    return next();
  }
});

export default router;
