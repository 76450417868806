/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  // addItem({ commit }, item) {
  //   return new Promise((resolve, reject) => {
  //     axios.post("/api/data-list/products/", {item: item})
  //       .then((response) => {
  //         commit('ADD_ITEM', Object.assign(item, {id: response.data.id}))
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  fetchUsers({ commit }, data) {
    console.log("data is",data);
    return new Promise((resolve, reject) => {
      axios.get('admin/user-management/getallusers?page=' + data.page + '&limit=' + data.limit + '&search=' + data.search + '&sort=' + data.sort + '&dir=' + data.dir)
        .then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err)
        });
    })
  },
  fetchUser(context, userId) {
    return new Promise((resolve, reject) => {
      axios.get(`admin/user-management/userDetail/${userId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchPostalCodes({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.get('postalcodes?page=' + data.page + '&limit=' + data.limit + '&search=' + data.search)
        .then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err)
        });
    })
  },



  addNewUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/user-management/create-user`, user)
        .then((response) => {
          resolve(response)
        }).catch((e) => { reject(e) })
    })
  },
  updateAdminProfile({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios.post(`admins/profileUpdate`, user)
        .then((response) => {
          commit("UPDATE_USER_INFO", response.data.data, {
            root: true,
          });
          resolve(response)
        }).catch((e) => { reject(e) })
    })
  },
  updateUserDetails({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios.put(`admin/user-management/userDetail/${user._id}`, user)
        .then((response) => {
          resolve(response)
        }).catch((e) => { reject(e) })
    })
  },
  removeUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`admin/user-management/userDetail/${id}`)
        .then((response) => {
          // commit('REMOVE_RECORD', id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchUserForCsv() {
    return new Promise((resolve, reject) => {
      axios.get(`admin/user-management/getUserForCsv`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateAdminImage({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.post(`api/v1/admins/profileImageUpdate`, data, {
            timeout: 500000
          })
        .then((response) => {
          resolve(response)
        }).catch((e) => { console.log(e); reject(e) })
    })
  }
}
