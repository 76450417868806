/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

// import moduleTodo from './todo/moduleTodo.js'
import moduleAdmin from "./admin/moduleAdmin.js";
import moduleEmail from "./email/moduleEmail.js";
import moduleAuth from "./auth/moduleAuth.js";
import modulePage from "./page/modulePage.js";
import moduleUser from "./user-management/moduleUserManagement.js";

import moduleSubscription from "./subscription/module";
import modulepostalCode from './postalCodes/module'



export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    // todo: moduleTodo,
    auth: moduleAuth,
    admin: moduleAdmin,
    page: modulePage,
    email: moduleEmail,
    user: moduleUser,
    subscription:moduleSubscription,
    postalCode:modulepostalCode,


  },
  strict: process.env.NODE_ENV !== "production"
});
