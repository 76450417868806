import Vue from "vue";
import { AclInstaller, AclCreate, AclRule } from "vue-acl";
import router from "@/router";

Vue.use(AclInstaller);

let initialRole = "admin";

let userInfo = JSON.parse(localStorage.getItem("userInfo"));
if (userInfo && userInfo.userType) initialRole = userInfo.userType;
export default new AclCreate({
  initial: initialRole,
  notfound: "/pages/not-authorized",
  router,
  acceptLocalRules: true,
  globalRules: {
    editor: new AclRule("editor").or("admin").or("superAdmin").generate(),
    admin: new AclRule("admin").or("superAdmin").generate(),
    superAdmin: new AclRule("superAdmin").generate(),
  },
});
