import axios from "@/axios";

export default {
  fetchSubscriptions({ commit }, data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      axios.get('/admins/subscriptions?page=' + data.page + '&limit=' + data.limit + '&search=' + data.search + '&sort=' + data.sort + '&dir=' + data.dir)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchSubscriptionDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get('/admins/subscription/'+id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  restoreSubscription({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get('/admins/subscription/restore/'+id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
