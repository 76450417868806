import axios from "@/axios";

export default {

  //dashboard
  fetchCardDetails({ commit }, data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      axios.get('admin/dashboard/getCardDetails')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  //dashboard
  fetchYearList({ commit }, data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      axios.get('admin/dashboard/getYearList')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  //dashboard
  fetchChartsDetails({ commit }, year) {
    return new Promise((resolve, reject) => {
      axios.get(`admin/dashboard/getChartDetails?year=${year}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addNewAdmin({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios.post(`admins/create-admin`, user)
        .then((response) => {
          resolve(response)
        }).catch((e) => { reject(e) })
    })
  },
  fetchAdmins({ commit }, data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      axios.get('/admins/adminDetails?page=' + data.page + '&limit=' + data.limit + '&search=' + data.search + '&sort=' + data.sort + '&dir=' + data.dir)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchAdminDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get('admins/adminDetails/' + id).then((res) => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  },

  updateAdminDetails({ commit }, data) {
    console.log(data)
    return new Promise((resolve, reject) => {
      axios.post('admins/adminDetails/update/' + data._id, data).then((res) => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  },
  changePassword({ commit }, data) {
    console.log(data)
    return new Promise((resolve, reject) => {
      axios.post('admins/changePassword/' + data.id, data).then((res) => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  },
  saveAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post('/api/v1/register', data.info).then((res) => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteAdmin({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete('/admins/adminDetails/' + id).then((res) => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  },
}
