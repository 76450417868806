import axios from '@/axios';

export default {
    fetchPages({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.get('admin/page?page=' + data.page + '&limit=' + data.limit + '&search=' + data.search + '&sort=' + data.sort + '&dir=' + data.dir)
                .then((res) => {
                    resolve(res);
                }).catch((err) => {
                    reject(err)
                });
        })
    },
    fetchPageDetails({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios.get('admin/page/' + id).then((res) => {
                resolve(res);
            }).catch(err => {
                reject(err)

            })
        })
    },

    savePagesSettings({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.post('admin/page/setPageStyle', data).then((res) => {
                resolve(res);
            }).catch(err => {
                reject(err)
            })
        })
    },
    getPagesSettings({ commit }, slug) {
        return new Promise((resolve, reject) => {
            axios.get('admin/page/setPageStyle?slug=' + slug).then((res) => {
                resolve(res);
            }).catch(err => {
                reject(err)
            })
        })
    },






    updatePageDetails({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.patch('admin/page/' + data.id, data.info).then((res) => {
                resolve(res);
            }).catch(err => {
                reject(err)
            })
        })
    },

    fetchPageDetailsBySlug({ commit }, slug) {
        return new Promise((resolve, reject) => {
            axios.get('page/findWithSlug?slug=' + slug).then((res) => {
                resolve(res);
            }).catch(err => {
                reject(err)
            })
        })
    },

}
