/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import App from "./App.vue";

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);

// Vue Router
import router from "./router";

//vue-apollo for graphql requests
import VueApollo from 'vue-apollo';
import ApolloClient from 'apollo-boost';
Vue.use(VueApollo);

console.log(process.env.VUE_APP_API_URL);

const client = new ApolloClient({
  onError: (({ graphQLErrors, networkError }) => {
    if (graphQLErrors){
      graphQLErrors.map(({ message, locations, path }) => {
        
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          )
          if(message==="Expired/Invalid Token"){
              localStorage.clear()
              router.push('/admin/login')
          }
        }
          )
    }

    if (networkError) console.log(`[Network error]: ${networkError}`)
  }),
  uri: process.env.VUE_APP_API_URL,
  request: operation => {
    operation.setContext({
      headers: {
        authorization: localStorage.getItem("accessToken") || ''
      },
    });
  }
});
const apolloProvider = new VueApollo({
  defaultClient: client,
})

// axios
import axios from "./axios.js";
Vue.prototype.$http = axios;

// API Calls
import "./http/requests";

// mock
import "./fake-db/index.js";

// Theme Configurations
import "../themeConfig.js";

// Firebase
import "@/firebase/firebaseConfig";

// Auth0 Plugin
import AuthPlugin from "./plugins/auth";
Vue.use(AuthPlugin);

// ACL
import acl from "./acl/acl";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vuex Store
import store from "./store/store";

// i18n
import i18n from "./i18n/i18n";

// Vuexy Admin Filters
import "./filters/filters";

// Clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// Tour
import VueTour from "vue-tour";
Vue.use(VueTour);
require("vue-tour/dist/vue-tour.css");

// VeeValidate
import VeeValidate from "vee-validate";
VeeValidate.setMode("eager");

VeeValidate.Validator.localize("en", {
  custom: {
    password: {
      regex:
        "Password must contain at least 8 characters including one uppercase letter,one lowercase letter and one number.",
    },
  },
});

Vue.use(VeeValidate, {
  classes: true,
});

// Google Maps
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: "YOUR_API_KEY",
    libraries: "places", // This is required if you use the Auto complete plug-in
  },
});

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Feather font icon
require("./assets/css/iconfont.css");

import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

import VueGoogleAutocomplete from 'vue-google-autocomplete'

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  acl,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
