/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";

import firebase from "firebase/app";
import "firebase/auth";
import router from "@/router";
import httpStatusCode from "http-status-codes";
import { use } from "vee-validate/dist/vee-validate.minimal.esm";
import axios from "@/axios";

export default {
  loginAttempt({ dispatch }, payload) {
    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation,
    };

    // If remember_me is enabled change firebase Persistence
    if (!payload.checkbox_remember_me) {
      // Change firebase Persistence
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)

        // If success try to login
        .then(function () {
          dispatch("login", newPayload);
        })

        // If error notify
        .catch(function (err) {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation();

          payload.notify({
            time: 2500,
            title: "Error",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    } else {
      // Try to login
      dispatch("login", newPayload);
    }
  },
  login({ commit, state, dispatch }, payload) {
    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation();
      console.log("Payload", payload);
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning",
      });

      return false;
    }

    // Try to sigin
    firebase
      .auth()
      .signInWithEmailAndPassword(
        payload.userDetails.email,
        payload.userDetails.password
      )

      .then(
        (result) => {
          // Set FLAG username update required for updating username
          let isUsernameUpdateRequired = false;

          // if username is provided and updateUsername FLAG is true
          // set local username update FLAG to true
          // try to update username
          if (payload.updateUsername && payload.userDetails.displayName) {
            isUsernameUpdateRequired = true;

            dispatch("updateUsername", {
              user: result.user,
              username: payload.userDetails.displayName,
              notify: payload.notify,
              isReloadRequired: true,
            });
          }

          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation();

          // if username update is not required
          // just reload the page to get fresh data
          // set new user data in localstorage
          if (!isUsernameUpdateRequired) {
            router.push(router.currentRoute.query.to || "/");
            commit("UPDATE_USER_INFO", result.user.providerData[0], {
              root: true,
            });
          }
        },
        (err) => {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation();

          payload.notify({
            time: 2500,
            title: "Error",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      );
  },

  // Google Login
  loginWithGoogle({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning",
      });
      return false;
    }
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || "/");
        commit("UPDATE_USER_INFO", result.user.providerData[0], { root: true });
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
  },

  // Facebook Login
  loginWithFacebook({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning",
      });
      return false;
    }
    const provider = new firebase.auth.FacebookAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || "/");
        commit("UPDATE_USER_INFO", result.user.providerData[0], { root: true });
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
  },

  // Twitter Login
  loginWithTwitter({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning",
      });
      return false;
    }
    const provider = new firebase.auth.TwitterAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || "/");
        commit("UPDATE_USER_INFO", result.user.providerData[0], { root: true });
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
  },

  // Github Login
  loginWithGithub({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning",
      });
      return false;
    }
    const provider = new firebase.auth.GithubAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || "/");
        commit("UPDATE_USER_INFO", result.user.providerData[0], { root: true });
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
  },
  registerUser({ dispatch }, payload) {
    // create user using firebase
    firebase
      .auth()
      .createUserWithEmailAndPassword(
        payload.userDetails.email,
        payload.userDetails.password
      )
      .then(
        () => {
          payload.notify({
            title: "Account Created",
            text: "You are successfully registered!",
            iconPack: "feather",
            icon: "icon-check",
            color: "success",
          });

          const newPayload = {
            userDetails: payload.userDetails,
            notify: payload.notify,
            updateUsername: true,
          };
          dispatch("login", newPayload);
        },
        (error) => {
          payload.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      );
  },
  updateUsername({ commit }, payload) {
    payload.user
      .updateProfile({
        displayName: payload.displayName,
      })
      .then(() => {
        // If username update is success
        // update in localstorage
        let newUserData = Object.assign({}, payload.user.providerData[0]);
        newUserData.displayName = payload.displayName;
        commit("UPDATE_USER_INFO", newUserData, { root: true });

        // If reload is required to get fresh data after update
        // Reload current page
        if (payload.isReloadRequired) {
          router.push(router.currentRoute.query.to || "/");
        }
      })
      .catch((err) => {
        payload.notify({
          time: 8800,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
  },

  // login admin
  loginJWT({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .login(payload.userDetails.email, payload.userDetails.password)
        .then((response) => {
          console.log(response);
          // If there's user data in response
          // console.log(response.data);
          if (response.data) {
            // localStorage.setItem(
            //   "firstName",
            //   response.data.data.firstName
            // );
            localStorage.setItem("user", JSON.stringify(response.data.data));

            // Set accessToken and refresh token
            localStorage.setItem(
              "accessToken",
              response.data.data.token.accessToken
            );
            localStorage.setItem(
              "refreshToken",
              response.data.data.token.refreshToken
            );
            // var date = new Date().getTime() + 60 * 60 * 24 * 1000;
            localStorage.setItem(
              "tokenExpiry",
              response.data.data.token.accessTokenExpiresIn
            );
            localStorage.setItem("loggedIn", "true");

            // Update user details
            // console.log("Updating user info");
            commit("UPDATE_USER_INFO", response.data.data, { root: true });

            // Set bearer token in axios
            console.log("Setting bearer token");
            commit("SET_BEARER", response.data.data.token.accessToken);

            router.push("/admin/dashboard");
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
          // reject(error)
        });
    });
  },
  registerUserJWT({ commit }, payload) {
    console.log("Re ", payload);
    const {
      displayName,
      email,
      password,
      confirmPassword,
      contactNumber,
      userType,
    } = payload.userDetails;

    return new Promise((resolve, reject) => {
      // Check confirm password
      if (password !== confirmPassword) {
        reject({ message: "Password doesn't match. Please try again." });
      }
      if (userType === "franchise") {
        jwt
          .registerFranchise(payload.userDetails)
          .then((response) => {
            // Redirect User
            router.push(router.currentRoute.query.to || "/");

            // Update data in localStorage
            localStorage.setItem("accessToken", response.data.accessToken);
            commit("UPDATE_USER_INFO", response.data.userData, { root: true });

            resolve(response);
          })
          .catch((error) => {
            // console.log(error.response)
            // console.log(response.data)
            reject(error);
          });
      } else {
        jwt
          .registerUser(displayName, email, password, userType)
          .then((response) => {
            // Redirect User
            router.push(router.currentRoute.query.to || "/");

            // Update data in localStorage
            localStorage.setItem("accessToken", response.data.accessToken);
            commit("UPDATE_USER_INFO", response.data.userData, { root: true });

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
  fetchAccessToken() {
    return new Promise((resolve, reject) => {
      jwt
        .refreshToken()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          //If JWT login
          if (localStorage.getItem("accessToken")) {
            localStorage.clear();
            router.push(router.currentRoute.query.to || "/");
          }
          reject(error);
        });
    });
  },
  resetPassword({ commit }, payload) {
    console.log(payload);

    return new Promise((resolve, reject) => {
      jwt
        .resetPassword(payload.userDetails.password, payload.userDetails.token)
        .then((response) => {
          // If there's user data in response
          if (response.status == httpStatusCode.OK) {
            console.log("Response found");
            // router.push('/admin/dashboard')
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          console.log(response.data.message);
          reject({ message: response.data.message });
        });
    });
  },
  forgotPwd({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .forgotPasswordAdmin(payload.userDetail.email)
        .then((response) => {
          if (response.data) {
            console.log("RESPONSE", response);
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },
  changeUserPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      console.log(payload);
      jwt
        .changePassword(
          payload.userDetails.currentPassword,
          payload.userDetails.newPassword
        )
        .then((response) => {
          // If there's user data in response
          if (response.status == httpStatusCode.OK) {
            console.log("Response found");
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          console.log(response.data.message);
          reject({ message: response.data.message });
        });
    });
  },

  checkTokenExpiry({ commit }, payload) {
    return new Promise((resolve, reject) => {
      console.log(payload);
      jwt
        .checkTokenExpiry(payload.token)
        .then((response) => {
          // If there's user data in response
          if (response.status == httpStatusCode.OK) {
            console.log("Response found");
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          console.log(response.data.message);
          reject({ message: response.data.message });
        });
    });
  },
  isResetTokenExpired({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .checkResetTokenExpiry(payload.token)
        .then((response) => {
          if (response.status === httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          console.log("Catch block", response.data.message);
          reject({ message: response.data.message });
        });
    });
  },
  verifyMail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .verifyMail(payload.token)
        .then((response) => {
          if (response.status == httpStatusCode.OK) {
            console.log("Response found");
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          console.log(response.data.message);
          reject({ message: response.data.message });
        });
    });
  },

  verifyAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/user/verifyAccount", payload)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err.response);
        });
    });
  },
};
