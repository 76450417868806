import axios from "../../../axios/index.js"
import store from "../../../../store/store.js"
// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []
const apiAdminUrl = "admin/"
const apiUrl = process.env.VUE_APP_BASE_API_URL
const appLoading = document.getElementById("loading-bg");

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

// function addSubscriber(callback) {
//   subscribers.push(callback)
// }

export default {
  init() {
    axios.interceptors.request.use((config) => {
        appLoading.style.display = "inline-block";
      const token = localStorage.getItem('accessToken');
      if (token) {
        config.headers.Authorization = 'Bearer ' + token;
      }
      return config;
    }, (error) => {
      return Promise.reject(error);
    });
    axios.interceptors.response.use(function (response) {
      if (appLoading) {
        appLoading.style.display = "none";
      }
      return response
    }, function (error) {
      if (appLoading) {
        appLoading.style.display = "none";
      }
      console.log('error ', error)
      // const { config, response: { status } } = error
      const { config, response } = error
      const originalRequest = config
      // if (status === 401) {
      if (response && response.status === 401) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          store.dispatch("auth/fetchAccessToken")
            .then((access_token) => {
              isAlreadyFetchingAccessToken = false
              onAccessTokenFetched(access_token)
            })
        }

        // const retryOriginalRequest = new Promise((resolve) => {
        //   addSubscriber(access_token => {
        //     originalRequest.headers.Authorization = 'Bearer ' + access_token
        //     resolve(axios(originalRequest))
        //   })
        // })
        // return retryOriginalRequest
      }
      return Promise.reject(error)
    })
  },
  login(email, pwd) {
    return axios.post(apiAdminUrl + "auth/login", { email: email, password: pwd })
  },
  refreshToken() {
    return axios.post("auth/refreshToken", { refreshToken: JSON.stringify(localStorage.getItem("refreshToKen")) })
  },
  checkTokenExpiry(token) {
    return axios.post("checkTokenExpiry", { token })
  },
  resetPassword(password, token) {
    return axios.post('auth/resetPassword', { password: password, token: token })
  },
  forgotPasswordAdmin(email) {
    return axios.post("auth/forgotPassword", { email: email })
  },
  changePassword(currentPassword, newPassword) {
    return axios.post("user/changePassword", { oldPassword: currentPassword, newPassword: newPassword })
  },

  checkResetTokenExpiry(token) {
    return axios.get(`api/v1/auth/isResetTokenExpired?token=${token}`);
  },
  verifyMail(token) {
    return axios.get(`auth/verify/${token}`);
  }
}
