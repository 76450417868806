import axios from "@/axios";
import httpStatusCode from "http-status-codes";

export default {
  fetchPostalCodes({ commit }, data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      axios.get('/admin/postalCode?page=' + data.page + '&limit=' + data.limit + '&search=' + data.search + '&sort=' + data.sort + '&dir=' + data.dir)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchPostalCodeDetails({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/admin/postalCode/${id}`).then(response => {
            if(response.status === httpStatusCode.OK) {
                resolve(response);
            } else {
                reject({message: response.data.message});
            }
        }).catch(error => reject(error));
    });
  },
  updatePostalCode({commit},data){
    return new Promise((resolve, reject) => {
      axios.post('/admin/postalCode/update',data).then(response => {
            if(response.status === httpStatusCode.OK) {
                resolve(response);
            } else {
                reject({message: response.data.message});
            }
        }).catch(error => reject(error));
    });
  },
  deletePostalCode({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/admin/postalCode/${id}`).then(response => {
            if(response.status === httpStatusCode.OK) {
                resolve(response);
            } else {
                reject({message: response.data.message});
            }
        }).catch(error => reject(error));
    });
  },
}
